import { NavigationBarFooter, NavigationBarLayout } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { FC, ReactNode, useState, PropsWithChildren } from 'react';
import CenterContainer from '../../components/CenterContainer';
import RefreshError from '../../components/RefreshError';
import { BreadcrumbHeadNode } from '../../createRoutes';
import ErrorBoundary from '../../ErrorBoundary';
import getValue from '../../helpers/getValue';
import { Environment, getCurrentEnvironment } from '../../helpers/onEnvironment';
import Breadcrumbs from '../Breadcrumbs';
import NavigationList from './NavigationList';
import UserMenuButton from './UserMenuButton';

const useStyles = makeStyles({ userMenuButton: { marginLeft: '.25rem' } });

const Page: FC<
    PropsWithChildren<{ breadcrumbs: BreadcrumbHeadNode; children: ReactNode }>
> = ({ children, breadcrumbs }) => {
    const [menuClosed, setMenuClosed] = useState(false);
    const classes = useStyles();

    const navigationList = (() => <NavigationList />)();

    return (
        <NavigationBarLayout
            closed={menuClosed}
            onMenuClose={() => setMenuClosed(true)}
            onMenuOpen={() => setMenuClosed(false)}
            navigationList={navigationList}
            breadcrumbs={<Breadcrumbs node={breadcrumbs} />}
            environmentBadge={getValue(() => {
                switch (getCurrentEnvironment()) {
                    case Environment.Local:
                        return 'Local';
                    case Environment.Staging:
                        return 'Staging';
                    case Environment.Production:
                        return undefined;
                    default:
                        throw new Error('Unhandled environment');
                }
            })}
            actions={
                <>
                    <UserMenuButton className={classes.userMenuButton} />
                </>
            }
            navigationBarFooter={<NavigationBarFooter closed={menuClosed} />}
        >
            <ErrorBoundary
                error={
                    <CenterContainer>
                        <RefreshError />
                    </CenterContainer>
                }
            >
                {children}
            </ErrorBoundary>
        </NavigationBarLayout>
    );
};

export default Page;
