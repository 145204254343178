 

export enum Environment {
    Local,
    Staging,
    Production,
}

export function getCurrentEnvironment(): Environment {
    const isStaging = process.env.REACT_APP_IS_STAGING === 'true';

    if (isStaging) {
        return Environment.Staging;
    }

    const envString = process.env.NODE_ENV;

    switch (envString) {
        case 'development':
            return Environment.Local;

        case 'production':
            return Environment.Production;

        default:
            throw new Error(`Unhandled NODE_ENV: ${envString}`);
    }
}

export default function onEnvironment(
    environment: Environment,
    ...other: Environment[]
): boolean {
    return [environment, ...other].includes(getCurrentEnvironment());
}
