import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Content';

export enum FlightTabs {
    ToBook,
    ToCancel,
    Processed,
}

export const toBookPage: PageDefinition = {
    path: '/flights/to-book',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning flights' }}>
                <Content tab={FlightTabs.ToBook} />
            </Page>
        );
    },
};

export const toCancelPage: PageDefinition = {
    path: '/flights/to-cancel',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning flights' }}>
                <Content tab={FlightTabs.ToCancel} />
            </Page>
        );
    },
};
export const processedPage: PageDefinition = {
    path: '/flights/processed',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'Positioning flights' }}>
                <Content tab={FlightTabs.Processed} />
            </Page>
        );
    },
};
