import apiClient from '../../../services/api';
import { Period } from './types';

const downloadExcel = async (period: Period): Promise<unknown>  => {
    const response = await apiClient.get(`/api/archived-flights-excel?start_time=${period?.start}&end_time=${period?.end}`,  {
        responseType: 'blob', 
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch((error) => {
        console.log(error);
      });;
    return response;
};

export default downloadExcel;