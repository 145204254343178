import { useEffect, useState } from 'react';

const delayMilliseconds = 1000;

export default function useLoaderDebounce(isLoading: boolean): boolean {
    const [debouncedValue, setDebouncedValue] = useState(false);

    useEffect(
        () => {
            if (!isLoading) {
                setDebouncedValue(false);
                return;
            }

            const handle = setTimeout(() => {
                setDebouncedValue(true);
            }, delayMilliseconds);

            return () => {
                clearTimeout(handle);
            };
        },
        [isLoading]
    );

    return debouncedValue;
}
