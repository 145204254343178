import i18next, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SupportedLocale } from '../context/LocaleContext';
import english from './en-GB.json';

const resources: {
    [locale in SupportedLocale]: ResourceLanguage;
} = { 'en-GB': { translation: english } };

const initializing = i18next.use(initReactI18next).init({
    resources,
    saveMissing: true,
    missingKeyHandler(_lngs, _ns, key) {
        throw new Error(`Missing key "${key}" for locale ${i18next.language}`);
    },

    // React renderer already escapes text nodes, this prevents double escaping
    interpolation: { escapeValue: false },
});

export async function setLocale(locale: SupportedLocale): Promise<void> {
    await initializing;
    await i18next.changeLanguage(locale);
}
