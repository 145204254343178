import { SignedOutLayout, TertiaryButtonLink } from '@get-e/react-components';
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Typography } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

const useStyles = makeStyles({
    container: {
        padding: '2em',
        maxWidth: '320px',
        margin: '0 auto',
    },
    heading: { marginBottom: '1rem' },
    backButtonContainer: {
        marginTop: '1rem',
        textAlign: 'center',
    },
});

const ForgotPassword: FunctionComponent<PropsWithChildren<unknown>> = () => {
    const classes = useStyles();

    return (
        <SignedOutLayout>
            <Paper elevation={0} className={classes.container}>
                <Typography variant="h2" component="h2" className={classes.heading}>
                    Forgot password?
                </Typography>

                <p>Contact your system administrator to reset your password.</p>

                <div className={classes.backButtonContainer}>
                    <TertiaryButtonLink to="/sign-in">Back to sign in</TertiaryButtonLink>
                </div>
            </Paper>
        </SignedOutLayout>
    );
};

export default ForgotPassword;
