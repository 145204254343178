import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SecondaryButton } from '@get-e/react-components';
import { format } from 'date-fns';
import moment from 'moment';

import { FetchResult, FilghtToDisplay } from './api/types';

const getStatusName = (status: string) => {
    switch (status) {
        case 'IGNORED':
            return 'Ignored';
        case 'NOT_BOOKED':
            return 'To Book';
        case 'TO_CANCEL':
            return 'To Cancel';
        case 'CANCELLED':
            return 'Cancelled';
        case 'BOOKED':
            return 'Booked';
    }
};

export const getColumnsToBook = (rows: FetchResult[]): GridColDef[] => {
    const uniques = getUniqueAirlines(rows);

    return [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 130,
            valueFormatter: params => format(params.value, 'dd MMM yyyy'),
        },
        {
            field: 'route',
            headerName: 'Route',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div className="dataGridRoute">
                    {params?.row?.route}
                    <span className="dataGridTime">
                        {params?.row?.start_time}-{params?.row?.end_time}
                    </span>
                </div>
            ),
        },
        {
            field: 'airline',
            headerName: 'Airline',
            minWidth: 60,
            type: 'singleSelect',
            valueOptions: () => uniques ?? [],
        },
        {
            field: 'flight',
            headerName: 'Flight',
            minWidth: 80,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 230,
            flex: 1,
        },
        {
            field: 'duty_des',
            headerName: 'Duty Des.',
            minWidth: 100,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 150,
            renderCell: (params: GridRenderCellParams<string>) => (
                <SecondaryButton
                    small
                    onClick={() => console.log('')}
                    className={`dataGridStatus${params.row.statusStyle}`}
                >
                    {params?.row?.status}
                </SecondaryButton>
            ),
        },
    ];
};

export const getColumnFlights = (rows: FetchResult[]): GridColDef[] => {
    const uniques = getUniqueAirlines(rows);
    return [
        {
            field: 'date',
            headerName: 'Date',
            minWidth: 130,
            valueFormatter: params => format(params.value, 'dd MMM yyyy'),
        },
        {
            field: 'route',
            headerName: 'Route',
            minWidth: 100,
            renderCell: (params: GridRenderCellParams<string>) => (
                <div className="dataGridRoute">
                    {params?.row?.route}
                    <span className="dataGridTime">
                        {params?.row?.start_time}-{params?.row?.end_time}
                    </span>
                </div>
            ),
        },
        {
            field: 'airline',
            headerName: 'Airline',
            minWidth: 60,
            type: 'singleSelect',
            valueOptions: () => uniques ?? [],
        },
        {
            field: 'flight',
            headerName: 'Flight',
            minWidth: 80,
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 220,
        },
        {
            field: 'duty_des',
            headerName: 'Duty Des.',
            minWidth: 80,
        },
        {
            field: 'ticket',
            headerName: 'Ticket',
            minWidth: 100,
        },
        {
            field: 'refundable',
            headerName: 'Refund.',
            minWidth: 20,
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 130,
            renderCell: (params: GridRenderCellParams<string>) => (
                <SecondaryButton
                    small
                    onClick={() => console.log('')}
                    className={`dataGridStatus${params.row.statusStyle}`}
                >
                    {params?.row?.status}
                </SecondaryButton>
            ),
        },
    ];
};

export const getUniqueAirlines = (toBookData: any) => {
    const airlines = toBookData.map((fl: any) => {
        return fl.flight.airline;
    });
    return airlines.filter((v: any, i: any, a: any) => a.indexOf(v) === i && v !== '');
};

export const mapToBookRow = (rows: FetchResult[]): FilghtToDisplay[] => {
    return rows.map(row => {
        return {
            id: row.hash,
            date: moment(row.flight.start_time).valueOf(),
            route: `${row.flight.departure} - ${row.flight.arrival}`,
            airline: row.flight.airline,
            flight: row.flight.number,
            name: `${row.employee.first_name} ${row.employee.last_name}`,
            duty_des: row.flight.duty_designation,
            status: getStatusName(row.status),
            statusStyle: row.status,
            start_time: format(moment(row.flight.start_time).valueOf(), 'HH:mm'),
            end_time: format(moment(row.flight.end_time).valueOf(), 'HH:mm'),
            valueOptions: ['sk', 'mk'],
        };
    });
};

export const mapFlightsRow = (rows: FetchResult[]) => {
    return rows.map(row => {
        return {
            id: row.id ?? row.hash,
            date: moment(row?.flight?.start_time).valueOf(),
            route: `${row.flight.departure} - ${row.flight.arrival}`,
            airline: row.flight.airline,
            flight: row.flight.number,
            name: `${row.employee.first_name} ${row.employee.last_name}`,
            duty_des: row.flight.duty_designation,
            status: getStatusName(row.status),
            ticket: row?.ticket_number ? row.ticket_number : '-',
            refundable: row?.refundable ? 'X' : '',
            statusStyle: row.status,
            start_time: format(moment(row.flight.start_time).valueOf(), 'HH:mm'),
            end_time: format(moment(row.flight.end_time).valueOf(), 'HH:mm'),
        };
    });
};
