import { makeStyles } from '@mui/styles';

export const useDataGridStyles = makeStyles({
    headerTitle: {
        fontWeight: '600',
    },
    scrollContent: {
        maxHeight: 1000,
    },
    root: {
        border: 'none',
        borderRadius: '0px 0px 4px 4px',
    },
    dataGrid: {
        marginTop: '32px',

        '& .dataGridStatus': {
            '&IGNORED': {
                color: '#788B9B',
                border: '1px solid #788B9B',
            },
            '&NOT_BOOKED': {
                color: '#E99A00',
                border: '1px solid #E99A00',
            },
            '&BOOKED': {
                color: '#12944A',
                border: '1px solid #12944A',
            },
            '&CANCELLED': {
                color: '#B72323',
                border: '1px solid #B72323',
            },
            '&TO_CANCEL': {
                color: '#F86909',
                border: '1px solid #F86909',
            },
        },

        '& .dataGridTime': {
            fontSize: 12,
            color: '#788B9B',
        },

        '& .dataGridRoute': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    processed: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'end',
    },
    processedSelect: {
        minWidth: 150,
    },
    processedDownload: {
        marginTop: '6px',
        marginLeft: '20px',
    },
});
