import { DependencyList, useCallback, useEffect } from 'react';

/**
 * Important note: The effect will NOT be awaited!
 *
 * This is simply a convenience function for using await inside a useEffect.
 */
export default function useEffectAsync(
    effect: () => Promise<void>,
    deps?: DependencyList,
): void {
    const effectMemo = useCallback(() => {
        effect();
    }, [effect]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectMemo, deps);
}
