import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import Content from './Content';


export const sasFlightsPage: PageDefinition = {
    path: '/sas-flights',
    Component() {
        return (
            <Page breadcrumbs={{ name: 'SAS flights' }}>
                <Content />
            </Page>
        );
    },
};