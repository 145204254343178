import { Component, ReactNode } from 'react';

interface Props {
    error: ReactNode;
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error): void {
        this.setState({ hasError: true });
    }

    render(): ReactNode {
        return this.state.hasError
            ? this.props.error
            : this.props.children;
    }
}

export default ErrorBoundary;
