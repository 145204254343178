import { NumberedTab, NumberedTabs, TabPanel } from '@get-e/react-components';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';
import { FlightTabs } from './index';
import ProcessedPanel from './ProcessedPanel';
import ToBookPanel from './ToBookPanel';
import ToCancelPanel from './ToCancelPanel';

const useStyles = makeStyles(() => ({
    tabContainer: {},
}));

interface FlightsPageProps {
    tab: FlightTabs;
}

const Content: FC<FlightsPageProps> = ({ tab }) => {
    const classes = useStyles();
    const [totalToBook, setTotalToBook] = useState(0);
    const [totalToCancel, setTotalToCancel] = useState(0);
    const [totalToProcess, setTotalToProcess] = useState(0);

    return (
        <div>
            <Grid container className={classes.tabContainer}>
                <Grid item xs={6}>
                    <NumberedTabs value={tab}>
                        <NumberedTab
                            number={totalToBook}
                            label="To book"
                            url="/flights/to-book"
                            index={FlightTabs.ToBook}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totalToCancel}
                            label="To cancel"
                            url="/flights/to-cancel"
                            index={FlightTabs.ToCancel}
                            selectedIndex={tab}
                        />
                        <NumberedTab
                            number={totalToProcess}
                            label="Processed"
                            index={FlightTabs.Processed}
                            selectedIndex={tab}
                            url="/flights/processed"
                        />
                    </NumberedTabs>
                </Grid>
            </Grid>
            <TabPanel value={FlightTabs.ToBook} selectedValue={tab}>
                <ToBookPanel
                    active={tab === FlightTabs.ToBook}
                    onUpdate={toBook => setTotalToBook(toBook)}
                />
            </TabPanel>

            <TabPanel value={FlightTabs.ToCancel} selectedValue={tab}>
                <ToCancelPanel
                    active={tab === FlightTabs.ToCancel}
                    onUpdate={toCancel => setTotalToCancel(toCancel)}
                />
            </TabPanel>
            <TabPanel value={FlightTabs.Processed} selectedValue={tab}>
                <ProcessedPanel active={tab === FlightTabs.Processed} onUpdate={toProcess => setTotalToProcess(toProcess)} />
            </TabPanel>
        </div>
    );
};

export default Content;
