import { MenuListItem } from '@get-e/react-components';
import { List } from '@mui/material';
import TicketIcon from '@mui/icons-material/AirplaneTicketOutlined';
import { FC, PropsWithChildren } from 'react';
import { matchPath } from 'react-router-dom';

function onPage(path: string): boolean {
    return Boolean(matchPath({ path }, window.location.pathname));
}

const NavigationList: FC<PropsWithChildren<unknown>> = () => (
    <List role="menu">
        <MenuListItem
            icon={<TicketIcon fontSize="small" />}
            label="Flights"
            href="/flights/to-book"
            selected={onPage('/flights/*')}
        />
        <MenuListItem
            icon={<TicketIcon fontSize="small" />}
            label="SAS Flights"
            href="/sas-flights"
            selected={onPage('/sas-flights/*')}
        />
    </List>
);

export default NavigationList;
