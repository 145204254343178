import { PrimaryButton, Select, SelectOption, DataGrid } from '@get-e/react-components';
import { eachMonthOfInterval, endOfMonth, subMonths } from 'date-fns';
import format from 'date-fns/format';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useDataGridStyles } from './styles/useDataGridStyles';
import GetAppIcon from '@mui/icons-material/GetApp';
import { GridRowId } from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import moment from 'moment';

import UpdateModal from './UpdateModal';
import { useProcessed } from './api/useProcessed';
import downloadExcel from './api/downloadExcel';
import { getColumnFlights, mapFlightsRow } from './data';
interface ProcessedProps {
    onUpdate: (toCancel: number) => void;
    active: boolean;
}

const mapSelectOption = (months: string[]) => {
    return months.map((month: string) => {
        return (
            <SelectOption key={month} value={month}>
                {month}
            </SelectOption>
        );
    });
};

const formatDate = (date?: Date) => {
    if (date) return format(date, 'yyyy-MM-dd');
};

const initialState: GridInitialStateCommunity = {
    sorting: {
        sortModel: [{ field: 'date', sort: 'asc' }],
    },
    filter: {
        filterModel: {
            items: [
                {
                    columnField: 'airline',
                    operatorValue: 'is',
                },
            ],
        },
    },
};

const ProcessedPanel: FC<ProcessedProps> = ({ onUpdate, active }) => {
    const period = eachMonthOfInterval({
        start: subMonths(new Date(), 11),
        end: new Date(),
    });

    const months = period.map((el: Date) => format(el, 'MMM, yyyy')).reverse();
    const [selectedMonth, setSelectedMonth] = useState('UPCOMING');
    const [openModal, setOpenModal] = useState<GridRowId | null>(null);

    const startMonth = period.find(item => {
        const monthShortName = selectedMonth.substring(0, 3);
        const monthNumber = moment(monthShortName, 'MMM').format('M');
        const itemMonth = (item.getMonth() + 1).toString();
        return itemMonth === monthNumber;
    });

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const {
        data: proccessedData = [],
        isFetching: isFetchingProccessed,
        refetch,
    } = useProcessed(active, startDate, endDate);

    const dataGridClasses = useDataGridStyles();
    const rows = proccessedData.length > 0 ? mapFlightsRow(proccessedData) : [];

    const { mutate: downloadExcelMutation, isLoading: isDownloadingExcel } =
        useMutation(downloadExcel);

    useEffect(() => {
        const isMonthSelected = selectedMonth !== 'UPCOMING' && selectedMonth !== '';
        setStartDate(isMonthSelected ? formatDate(startMonth) ?? '' : '');
        setEndDate(isMonthSelected ? formatDate(endOfMonth(startMonth ?? 1)) ?? '' : '');
    }, [selectedMonth, refetch, startMonth]);

    useEffect(() => {
        onUpdate?.(proccessedData?.length ?? 0);
    }, [proccessedData?.length, onUpdate]);

    useEffect(() => {
        refetch();
    }, [startDate, endDate, refetch]);

    const ticketData = useMemo(() => {
        if (proccessedData) {
            return proccessedData?.find(ticket => ticket.id === openModal);
        }
    }, [openModal, proccessedData]);

    const handleCloseModal = () => setOpenModal(null);

    const handlePeriodChange = (newMonth: string) => {
        setSelectedMonth(newMonth);
    };

    return (
        <>
            <div className={dataGridClasses.dataGrid}>
                <div className={dataGridClasses.processed}>
                    <div className={dataGridClasses.processedSelect}>
                        <Select
                            label="Period"
                            value={selectedMonth}
                            onChange={handlePeriodChange}
                        >
                            <SelectOption value="UPCOMING">Upcoming</SelectOption>
                            {mapSelectOption(months)}
                        </Select>
                    </div>
                    <div className={dataGridClasses.processedDownload}>
                        <PrimaryButton
                            onClick={() =>
                                downloadExcelMutation({
                                    start: startDate ?? '',
                                    end: endDate ?? '',
                                })
                            }
                            loading={isDownloadingExcel}
                            icon={<GetAppIcon fontSize="small" />}
                        >
                            Export
                        </PrimaryButton>
                    </div>
                </div>
                {active && (
                    <DataGrid
                        enableFilters
                        rows={rows}
                        columns={getColumnFlights(proccessedData)}
                        pageSize={20}
                        rowsPerPageOption={[20]}
                        autoHeight
                        loading={isFetchingProccessed}
                        onRowClick={row => setOpenModal(row.id)}
                        header="Processed flights archive"
                        noRows="No flights have been processed"
                        initialState={initialState}
                    />
                )}
                {openModal && (
                    <UpdateModal
                        open={openModal}
                        onClose={handleCloseModal}
                        ticketData={ticketData}
                    />
                )}
            </div>
        </>
    );
};

export default ProcessedPanel;
