import InputError from './InputError';

export default function getHelperText(error: InputError | null): string {
    switch (error) {
        case null:
            return '';
        case InputError.Required:
            return 'This field is required';
        case InputError.InvalidEmail:
            return 'Please provide a valid email address';
        case InputError.NoMatch:
            return 'Does not match, please try again';
        case InputError.InvalidPassword:
            return 'Password does not meet required criteria. Please try again.';
        default:
            throw new Error('Unhandled error');
    }
}
