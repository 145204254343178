import { User } from '../context/AuthenticatedUserContext';
import apiClient from './api';

export const login = async (email: string, password: string) => {
    await apiClient.get('/sanctum/csrf-cookie');
    const response = await apiClient.post<User>('api/login', {
        email,
        password,
    });

    return response.data;
};

export const logout = async () => {
    const response = await apiClient.post('api/logout');
    return response.data;
};
