import FormError from './FormError';

export default function getFormErrorMessage(error: FormError): string {
    switch (error) {
        case FormError.Unauthorized:
            return 'You are not authorized to do this.';
        case FormError.UnexpectedError:
            return 'An unexpected error occurred. Please try again or contact us.';
        case FormError.UserError:
            return 'Please resolve the errors above.';
        default:
            return 'An unexpected error occurred. Please try again or contact us.';
    }
}
