import { DataGrid, Heading } from '@get-e/react-components';
import { makeStyles } from '@mui/styles';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';
import { FC, useEffect, useRef, useState } from 'react';
import { Switch } from '@mui/material';
import moment from 'moment';

import { getColumns, mapToRow } from './data';
import { useSkFlights } from './api/useSkFlights';
import { FilghtToDisplay } from './api/types';
import { useDataGridStyles } from '../Flights/styles/useDataGridStyles';

const initialState: GridInitialStateCommunity = {
    sorting: {
        sortModel: [{ field: 'date', sort: 'asc' }],
    },
    filter: {
        filterModel: {
            items: [
                {
                    columnField: 'airline',
                    operatorValue: 'is',
                },
            ],
        },
    },
};

const useStyles = makeStyles(() => ({
    header: {
        display: 'flex',
        padding: '1rem 0.625rem',
        alignItems: 'center',
        border: '1px solid #e0e0e0',
        borderBottom: 'none',

        '& h2': {
            marginBottom: 0,
        },
    },
    quickFilterWrapper: {
        marginLeft: 'auto',
    },
}));

const Content: FC = () => {
    const classes = useStyles();
    const dataGridClasses = useDataGridStyles();
    const [rows, setRows] = useState<FilghtToDisplay[]>([]);
    const [isPublishedRosterOnly, setIsPublishedRosterOnly] = useState(false);
    const [rowsFiltered, setRowsFiltered] = useState<FilghtToDisplay[]>([]);
    const isQuickFilterInitialized = useRef(false);

    const { data: sasFlightsData = [], isFetching } = useSkFlights();

    useEffect(() => {
        if (!isFetching) {
            setRows(sasFlightsData.length > 0 ? mapToRow(sasFlightsData) : []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, sasFlightsData.length]);

    useEffect(() => {
        if (rows.length && !isQuickFilterInitialized.current) {
            isQuickFilterInitialized.current = true;
            handlePublishedRosterOnly(false);
        } else {
            handlePublishedRosterOnly(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows.length]);

    const handlePublishedRosterOnly = (isRefetch: boolean) => {
        const showPublishedRosters = isRefetch
            ? isPublishedRosterOnly
            : !isPublishedRosterOnly;
        setIsPublishedRosterOnly(showPublishedRosters);

        if (showPublishedRosters) {
            const utcDateTime = moment.utc();
            const currentUtcDay = utcDateTime.date();
            const currentUtcMonth = utcDateTime.month();
            const nextUtcMonth = utcDateTime.add(1, 'M').month();

            let publishedRosters: FilghtToDisplay[] = [];
            if (currentUtcDay < 15) {
                publishedRosters = rows.filter(booking => {
                    const bookingUtcMonth = moment(booking.date).month();
                    return bookingUtcMonth === currentUtcMonth;
                });
            } else {
                publishedRosters = rows.filter(booking => {
                    const bookingUtcMonth = moment(booking.date).month();
                    return (
                        bookingUtcMonth === currentUtcMonth ||
                        bookingUtcMonth === nextUtcMonth
                    );
                });
            }
            setRowsFiltered(publishedRosters);
        }
    };

    return (
        <div className={dataGridClasses.dataGrid}>
            <div className={classes.header}>
                <Heading level={2}>SAS flights</Heading>
                <div className={classes.quickFilterWrapper}>
                    <span>Published roster only</span>
                    <Switch
                        checked={isPublishedRosterOnly}
                        onChange={() => handlePublishedRosterOnly(false)}
                        name="loading"
                        color="primary"
                        disabled={isFetching}
                    />
                </div>
            </div>
            <DataGrid
                loading={isFetching}
                rows={isPublishedRosterOnly ? rowsFiltered : rows}
                columns={getColumns(sasFlightsData)}
                pageSize={20}
                rowsPerPageOption={[20]}
                autoHeight
                enableFilters
                noRows="No flights"
                initialState={initialState}
            />
        </div>
    );
};

export default Content;
